import "./Bing";
import i18n from "@/libs/i18n";
const language = localStorage.getItem("lang") || "en";
const languageList = (JSON.parse(localStorage.getItem("LANGUAGES")) || [])
  .filter((e) => e.locale === language)
  .map((e) => e.country_code);

const languageCode = languageList.length
  ? `${language}-${languageList[0]}`
  : "en-Uk";

const BING_MAP_KEY =
  "AmG6BbjpHnYxHf9uP5pXErMUNWTSVeOSXYcGfeF-oeFQgNfe6eNWVksRlFUoOZSC";

const osm = L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
  maxZoom: 19,
  attribution: "© OpenStreetMap"
});
const googleTraffic = L.tileLayer(
  "https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}&hl=" +
    language,
  {
    maxZoom: 20,
    minZoom: 2,
    subdomains: ["mt0", "mt1", "mt2", "mt3"],
    attribution: "© Google Map"
  }
);
const googleSat = L.tileLayer(
  "http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&hl=" + language,
  {
    maxZoom: 20,
    subdomains: ["mt0", "mt1", "mt2", "mt3"],
    attribution: "© Google Map"
  }
);
const googleHybrid = L.tileLayer(
  "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}&hl=" + language,
  {
    maxZoom: 20,
    subdomains: ["mt0", "mt1", "mt2", "mt3"],
    attribution: "© Google Map"
  }
);
const defaults = {
  maxZoom: 19,
  minZoom: 2,
  detectRetina: true,
  culture: languageCode
};
const bingAerialLayer = L.bingLayer(BING_MAP_KEY, {
  maxZoom: 19,
  minZoom: 2,
  detectRetina: true,
  imagerySet: "AerialWithLabelsOnDemand",
  culture: languageCode
});
const bingRoadOnDemand = L.bingLayer(BING_MAP_KEY, {
  ...defaults,
  imagerySet: "RoadOnDemand"
});

const mapLayerDefaultText = {
  OpenStreetMap: "OpenStreetMap",
  BingAerial: "Bing Aerial",
  BingRoadOnDemand: "Bing RoadOnDemand",
  GoogleHybrid: "Google Hybrid",
  GoogleTraffic: "Google Traffic",
  GoogleSatellite: "Google Satellite"
};

const mapLayersText = i18n?.messages?.[language]?.Map ?? {};

Object.keys(mapLayerDefaultText).forEach((layer) => {
  mapLayerDefaultText[layer] =
    mapLayersText[layer] || mapLayerDefaultText[layer];
});
localStorage.removeItem("default_map_layer");

localStorage.setItem("default_map_layer", [mapLayerDefaultText.OpenStreetMap]);

const MapLayers = {
  [mapLayerDefaultText.OpenStreetMap]: osm,
  [mapLayerDefaultText.BingAerial]: bingAerialLayer,
  [mapLayerDefaultText.BingRoadOnDemand]: bingRoadOnDemand,
  [mapLayerDefaultText.GoogleHybrid]: googleHybrid,
  [mapLayerDefaultText.GoogleTraffic]: googleTraffic,
  [mapLayerDefaultText.GoogleSatellite]: googleSat
};

export default MapLayers;
