<template>
  <div class="geofence-page-outer geofence-main">
    <SideBar
      @setSearchKey="setSearchKey"
      class="slide-bar"
      :openMapClass="openMapClass"
      :closeMenu="closeMenu"
      :geofenceControl="geofenceControl"
      @getCurrentTab="getCurrentTab"
    />
    <b-row>
      <b-col
        cols="12"
        class="mb-2-0 mob-0"
        :class="sidebarToggle ? 'sm-map' : 'lg-map'"
      >
        <Loader :show="show" :isHideLogo="true" style="z-index: 1000" />
        <div
          class="geofence-map-outer"
          :class="$route.name + ' ' + tab + '-tab-cls' + ' ' + searchKey"
        >
          <l-map
            ref="unitMap"
            :center="latLng"
            style="opacity: 1; z-index: 1000"
            :zoom="zoom"
          >
          </l-map>
        </div>
      </b-col>
    </b-row>
    <SearchCard
      :geofenceControl="geofenceControl"
      :searchKey="searchKey"
      @searchCardClose="searchCardClose"
      v-if="['geofence', 'unit'].includes(searchKey)"
    ></SearchCard>
  </div>
</template>
<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import SideBar from "./sidebar.vue";
import SearchCard from "@/views/Route/SearchCard.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { LMap } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import "@/libs/map/js/geofence";
import MapLayers from "@/libs/map/MapLayers";
import Loader from "@/layouts/components/Loader.vue";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster";
import "leaflet.featuregroup.subgroup";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import "leaflet-geosearch/assets/css/leaflet.css";
import { GeoSearchControl } from "leaflet-geosearch";
import * as Utils from "@/libs/utils";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    LMap,
    SideBar,
    SearchCard,
    Loader
  },
  data() {
    return {
      sidebarToggle: true,
      latLng: [0, 0],
      zoom: 5,
      closeMenu: false,
      show: false,
      geofenceControl: null,
      searchKey: "",
      tab: ""
    };
  },
  watch: {
    sidebarToggle(val) {
      setTimeout(() => {
        if (this.$refs.unitMap) {
          this.$refs.unitMap.mapObject.invalidateSize();
        }
      }, 100);
    },
    searchKey(val) {
      // if (!val) {
      //   this.$store.commit("route/SET_ROUTE_CHECKPOINT", []);
      // }
    },
    "$route.name"(to, from) {
      if (this.geofenceControl) {
        this.geofenceControl.removeAllLayers();
      }
      this.searchKey = "";
      this.$store.commit("route/SET_ROUTE_CHECKPOINT", []);
    }
  },
  beforeDestroy() {},
  mounted() {
    var map = this.$refs && this.$refs.unitMap && this.$refs.unitMap.mapObject;
    new MapLayers(map);

    // mySubGroup.addTo(map);
    map.invalidateSize();

    map.zoomControl._zoomInButton.title = this.$t("ZoomIn") || "Zoom In";
    map.zoomControl._zoomOutButton.title = this.$t("ZoomOut") || "Zoom Out";
    this.geofenceControl = L.control.geofence().addTo(map);
    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
      provider,
      autoComplete: true,
      autoCompleteDelay: 250,
      showMarker: false
    });
    map.addControl(searchControl);
    map.on("geosearch/showlocation", this.geosearch);
    Utils.getUsersCurrentLocation()
      .then((position) => {
        const location = new L.LatLng(position[0], position[1]);
        map.fitBounds(L.latLngBounds(location, location), {
          maxZoom: 14
        });
      })
      .catch((err) => {
        console.error(
          "User rejected location permission or browser does not support",
          err
        );
      });
    this.searchKey = "";
  },
  methods: {
    openMapClass(e) {
      this.sidebarToggle = e;
    },
    getCurrentTab(tab) {
      this.tab = tab;
    },
    geosearch(e) {
      this.latLng = [e.marker._latlng.lat, e.marker._latlng.lng];
    },
    onInfoMenuCloseMainModal(val) {
      this.closeMenu = val;
    },
    searchCardClose() {
      this.searchKey = "";
    },
    setSearchKey(key) {
      this.searchKey = key;
    }
  }
};
</script>
<style lang="scss">
@media screen and (min-width: 991px) {
  .geofence-main {
    .sm-map {
      .loading {
        left: calc(50% - 185px) !important;
      }
    }
  }
  .geofence-main {
    .sm-map {
      .loading {
        left: calc(50% - 185px) !important;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.vue2leaflet-map {
  &.leaflet-container {
    height: 600px;
    height: calc(100vh - 107px);
  }
}
.trans-bg {
  background: transparent !important;
}
.geofence-map-outer {
  width: 100%;
  z-index: 1;
  position: relative;
}
.leaflet-popup-content-wrapper {
  padding: 0px !important;
}
.leaflet-popup {
  bottom: 17px !important;
}

@media screen and (max-width: 991px) {
  .vue2leaflet-map {
    &.leaflet-container {
      height: 92vh;
    }
  }
}
@media screen and (max-width: 667px) {
  .vue2leaflet-map {
    &.leaflet-container {
      height: calc(100vh - 205px);
    }
  }
}
.sm-map {
  .geofence-map-outer {
    .vue2leaflet-map.leaflet-container {
      width: calc(100% - 400px);
      z-index: 1;
    }
  }
}
.lg-map {
  .geofence-map-outer {
    .vue2leaflet-map.leaflet-container {
      width: 100%;
    }
  }
}
@media screen and (max-width: 991px) {
  .sm-map {
    .geofence-map-outer {
      .vue2leaflet-map.leaflet-container {
        width: 100%;
      }
    }
  }
}
.slide-bar {
  z-index: 9;
}
.geofence-page-outer {
  position: relative;
  overflow: hidden;
  .search-card {
    max-width: 100%;
  }
}
.dark-layout {
  .slide-bar {
    z-index: 9;
    .vs__selected-options input,
    input {
      &::placeholder {
        color: var(--white) !important;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        color: var(--white) !important;
      }
      &::-ms-input-placeholder {
        color: var(--white) !important;
      }
    }
    .reportrange-text {
      span {
        color: var(--white) !important;
      }
    }
  }
}
</style>
<style lang="scss">
.geofences-list {
  .leaflet-draw.leaflet-control {
    display: none !important;
  }
  .geofence-map-outer {
    z-index: 1;
  }
}
.routes-list {
  .leaflet-draw.leaflet-control {
    display: none !important;
  }
  .geofence-map-outer {
    z-index: 1;
  }
}
.route-tab-cls {
  .leaflet-draw-draw-rectangle {
    display: none !important;
  }
  .leaflet-draw-draw-polygon {
    display: none !important;
  }
  .leaflet-draw-draw-circle {
    display: none !important;
  }
  .leaflet-draw-draw-circlemarker {
    display: none !important;
  }
  .leaflet-draw-draw-marker {
    display: none !important;
  }
}
.locationPoint {
  .leaflet-draw-draw-marker {
    display: block !important;
  }
}
.geofence-tab-cls {
  .leaflet-draw-draw-circlemarker {
    display: none !important;
  }
  .geofence-map-outer {
    z-index: 1;
  }
  .leaflet-draw-draw-marker {
    display: none !important;
  }
}
.leaflet-default-icon-path {
  background-image: url(https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png);
}
.route-tab-cls {
  .leaflet-draw-edit-edit {
    display: none !important;
  }
  .leaflet-draw-edit-remove {
    display: none !important;
  }
}
.leaflet-routing-container.leaflet-bar.leaflet-control {
  display: none !important;
}
.geofence-view {
  .leaflet-draw.leaflet-control {
    display: none !important;
  }
}
</style>
