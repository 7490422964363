import * as moment from "moment";

const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";
const DATE_FORMAT = "DD/MM/YYYY";
const TIME_FORMAT = "HH:mm";
const COLORS = [
  "#0037ff",
  "#420438",
  "#ff0000",
  "#043442",
  "#ff5e00",
  "#244204",
  "#9100ff",
  "#043442",
  "#ff00d4",
  "#000000",
  "#00811c",
  "#790081",
  "#006c8d",
  "#1e004e",
  "#6200ff",
  "#5e5b00",
  "#8B4513",
  "#90EE90",
  "#BC8F8F",
  "#FF69B4",
  "#D2B48C",
  "#FF8C00",
  "#B8860B",
  "#FF7F50",
  "#FA8072",
  "#000080"
];

export const COLOR = [
  "#8B4513",
  "#BC8F8F",
  "#FF69B4",
  "#D2B48C",
  "#BDB76B",
  "#1E90FF",
  "#DA70D6",
  "#4169E1",
  "#9ACD32",
  "#DB7093",
  "#DAA520",
  "#00BFFF",
  "#DEB887",
  "#FF8C00",
  "#B8860B",
  "#FF7F50",
  "#FA8072",
  "#000080",
  "#EE82EE",
  "#CD5C5C",
  "#6B8E23",
  "#00FF00",
  "#f8d893d1",
  "#c8920b",
  "#FFFF00",
  "#FF00FF",
  "#ff088e6e",
  "#800000",
  "#008000",
  "#808000",
  "#008080",
  "#C0C0C0",
  "#20b816",
  "#69ade9",
  "#808080",
  "#034041",
  "#FFC0CB",
  "#9400D3",
  "#DC143C",
  "#0000CD",
  "#077e468c",
  "#FF4500",
  "#8A2BE2",
  "#cf6519",
  "#6A5ACD",
  "#ADFF2F",
  "#8B008B",
  "#2E8B57",
  "#4682B4",
  "#CD853F",
  "#40E0D0",
  "#FFD700",
  "#556B2F",
  "#FF1493",
  "#00FA9A",
  "#B03060",
  "#CD96CD",
  "#008B8B",
  "#FFA54F",
  "#7D26CD",
  "#FFE4E1",
  "#DBDB70",
  "#2F4F2F",
  "#CD5555",
  "#FFA500",
  "#F0FFF0",
  "#00FF7F",
  "#EE3A8C",
  "#5F9F9F",
  "#70DB93",
  "#CD9B1D",
  "#9932CC",
  "#2F4F4F",
  "#B87333",
  "#F5DEB3",
  "#00FFFF",
  "#778899",
  "#A0522D",
  "#F08080",
  "#790081",
  "#FFDAB9",
  "#F0F8FF",
  "#92DFED",
  "#8BDE2C",
  "#3A512F",
  "#3DCAA7",
  "#EDCC9A",
  "#723F8C",
  "#E05C6F",
  "#E8496D",
  "#7E109A",
  "#B954D5",
  "#0C48E1",
  "#533E2B",
  "#BCE4EC",
  "#833017",
  "#2D6DA2",
  "#C5167A",
  "#8F3349",
  "#5A98FD",
  "#A1E264",
  "#70C195",
  "#70541A",
  "#9F57FC",
  "#A66346",
  "#6E5785",
  "#4F3D8A",
  "#AB279F",
  "#D110B3",
  "#9BCE4F",
  "#F74BBF",
  "#F7686A",
  "#BFC499",
  "#42938C",
  "#FBE994",
  "#CA0543",
  "#E07AC8",
  "#3524EC",
  "#A26D8E",
  "#DF1388",
  "#25A26B",
  "#5AD9B8",
  "#40F560",
  "#D13276",
  "#2B8F9A",
  "#7DD2A9",
  "#C7902A",
  "#7E23D1",
  "#65FE47",
  "#43182C",
  "#E44F7B",
  "#EAA977",
  "#B1EFC6",
  "#E7593E",
  "#1E621C",
  "#7EC2A5",
  "#0285BE",
  "#C7F1A6",
  "#17055B",
  "#135AE1",
  "#8BBA3D",
  "#5DE11C",
  "#B2E021",
  "#2F837D",
  "#D05033",
  "#01A59D",
  "#D4CFC3",
  "#AA2DBE",
  "#B83C6D",
  "#5AE959",
  "#36DDFA",
  "#A6F909",
  "#9E15A4",
  "#D068D0",
  "#6C7860",
  "#FD50C1",
  "#75331B",
  "#D529C7",
  "#4C8B72",
  "#55A831",
  "#F1252F",
  "#905AF9",
  "#BA31A0",
  "#9DE084",
  "#3D3988",
  "#0C31AB",
  "#0A4E3F",
  "#EB74A7",
  "#49D9AF",
  "#67867D",
  "#7C0F0B",
  "#AF095E",
  "#3B2D98",
  "#03C5BA",
  "#F26220",
  "#7E02DD",
  "#77D072",
  "#EA2C4E",
  "#7A5911",
  "#0A803A",
  "#C6F6A4",
  "#79B4CC",
  "#C95E86",
  "#F59A41",
  "#5B5508",
  "#B781E4",
  "#E6D7B9",
  "#4F9AA6",
  "#A13D33",
  "#0457CA",
  "#9C51A3",
  "#6CBDA2",
  "#0A4EB7",
  "#812B5B",
  "#242351",
  "#9DA5CA",
  "#242336",
  "#EB71FE",
  "#28400F",
  "#7F1770",
  "#4481B3",
  "#80A5E7",
  "#076F88",
  "#97488D",
  "#B01452",
  "#C439ED",
  "#998A3F",
  "#EB66B8",
  "#4BBBED",
  "#91FB96",
  "#5AEFEE",
  "#C483DF",
  "#DB3CE4",
  "#C0DB07",
  "#C0EC46",
  "#1C7DB6",
  "#EEC9FF",
  "#987BA6",
  "#AAF460",
  "#0DE8BA",
  "#A70B26",
  "#298800",
  "#447696",
  "#059E55",
  "#C6C401",
  "#C17A9C",
  "#092A0A",
  "#96A66A",
  "#BF880D",
  "#853C15",
  "#05955E",
  "#0ADF61",
  "#448263",
  "#E8938F",
  "#4F12F4",
  "#E3EC29",
  "#DAF24D",
  "#36E907",
  "#6BB071",
  "#2F6558",
  "#F5C98F",
  "#8121CF",
  "#B423E9",
  "#896F9F",
  "#FC5493",
  "#13D6A4",
  "#886AAF",
  "#BD2AB3",
  "#CF0D05",
  "#E08BD3",
  "#A7E56C",
  "#4920C1",
  "#2B9408",
  "#6DD829",
  "#B63CA4",
  "#3615FE",
  "#197E67",
  "#7C7DD0",
  "#88B8B2",
  "#C79EBC",
  "#43CC89",
  "#26414B",
  "#1DC97C",
  "#B11375",
  "#4CB26B",
  "#DBBAD2",
  "#8E007E",
  "#471924",
  "#6DEC0E",
  "#E72636",
  "#5177B7",
  "#EF28D1",
  "#E789A4",
  "#3971D5",
  "#66F944",
  "#214B6F",
  "#AF9F37",
  "#C33CFC",
  "#5299A4",
  "#DBBBE4",
  "#90B270",
  "#FD7B24"
];

export function formatDate(date) {
  return moment(date).format(DATE_FORMAT);
}
export function formatTime(date) {
  return moment(date).format(TIME_FORMAT);
}
export function formatDateTime(date) {
  return moment(date).format(DATE_TIME_FORMAT);
}
export function randomDarkColor(index) {
  return COLORS[index % COLORS.length];
}
export function randomDarkChartColors(index) {
  return [...new Set(COLOR)];
}
export function randomDarkChartColor(index) {
  return [...new Set(COLOR)][index % COLORS.length];
}
export function getColors(count) {
  const numbers = Array.from(
    {
      length: COLOR.length
    },
    (_, index) => index
  ); // Create an array from 0 to 20
  const shuffledNumbers = numbers.sort(() => Math.random() - 0.5);
  const uniqueNumbersSubset = shuffledNumbers.slice(0, count);
  const colors = [];
  for (let i = 0; i < count; i++) {
    colors.push(COLOR[uniqueNumbersSubset[i]]);
  }

  return colors;
}
export function randomColor(rRange = 255, gRange = 255, bRange = 255) {
  var r = Math.floor(Math.random() * rRange);
  var g = Math.floor(Math.random() * gRange);
  var b = Math.floor(Math.random() * bRange);
  return "rgb(" + r + " ," + g + "," + b + ")";
}

export async function getUsersCurrentLocation() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        resolve([position.coords.latitude, position.coords.longitude]);
      }, reject);
    } else {
      reject({
        message: "Browser does not support location"
      });
    }
  });
}
export function calculateDistanceInPx(map, lats) {
  let total = 0;
  if (lats.length <= 1) {
    return total;
  }
  for (let i = 0; i < lats.length - 1; i++) {
    const point1 = map.latLngToLayerPoint(lats[i]);
    if (lats[i + 1]) {
      const point2 = map.latLngToLayerPoint(lats[i + 1]);
      total += Number(
        Math.sqrt((point1.x - point2.x) ** 2 + (point1.y - point2.y) ** 2)
      );
    }
  }
  return total;
}
export class EventEmitter {
  constructor() {
    this.callbacks = {};
  }

  on(event, cb) {
    if (!this.callbacks[event]) this.callbacks[event] = [];
    this.callbacks[event].push(cb);
  }

  emit(event, data) {
    let cbs = this.callbacks[event];
    if (cbs) {
      cbs.forEach((cb) => cb(data));
    }
  }
}
