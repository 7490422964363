<template id="slidemenu">
  <div class="menu" :class="open ? 'sm-info' : 'lg-info'">
    <div @click="toggleNav" :class="['toggle-button', { 'toggle-open': open }]">
      <MenuIcoIn v-if="open" />
      <MenuIcoOut v-else />
    </div>

    <nav
      :class="[
        'menu__nav',
        { 'menu__nav--open': open },
        { up: positionClass },
        { down: !positionClass }
      ]"
    >
      <div
        class="arrow bounce"
        @touchstart="onTouchStart"
        @touchmove="onTouchMove"
        @touchend="onTouchEnd"
        @click="onTouchclick"
      >
        <feather-icon
          v-if="!positionClass"
          class="text-white iconpos"
          icon="ChevronsUpIcon"
          size="17"
        />
        <feather-icon
          v-if="positionClass"
          class="text-white iconpos"
          icon="ChevronsDownIcon"
          size="17"
        />
      </div>
      <div class="filter-tab geofece-tab">
        <b-tabs>
          <b-tab
            :title="$t('Geofence.TabGeofences')"
            @click="activeTab('geofence')"
            :active="selectedTab === 'geofence'"
          >
            <GeofencesList
              :geofenceControl="geofenceControl"
              v-if="$route.name === 'geofences-list'"
            />
            <GeofencesAdd
              :geofenceControl="geofenceControl"
              v-else-if="
                ['geofence-create', 'geofence-view', 'geofence-edit'].includes(
                  $route.name
                )
              "
            />
          </b-tab>
          <b-tab
            :title="$t('route.TabRoutes')"
            :active="selectedTab === 'route'"
            @click="activeTab('route')"
          >
            <RoutesList
              :geofenceControl="geofenceControl"
              v-if="$route.name === 'routes-list'"
            />
            <AddRoutes
              :geofenceControl="geofenceControl"
              @setSearchKey="setSearchKey"
              v-else-if="
                ['route-create', 'route-view', 'route-edit'].includes(
                  $route.name
                )
              "
            />
          </b-tab>
          <!-- <b-tab :title="$t('Geofence.TabRoutes')"> </b-tab> -->
        </b-tabs>
      </div>
    </nav>
  </div>
</template>

<script>
import { VBTooltip, BTabs, BTab } from "bootstrap-vue";
import constants from "@/utils/constants";
import MenuIcoIn from "@/assets/images/unit-icon/side-bar-toggle-out.svg";
import MenuIcoOut from "@/assets/images/unit-icon/side-bar-toggle-in.svg";
import GeofencesList from "./GeofencesList.vue";
import store from "@/store";
import RoutesList from "@/views/Route/RoutesList.vue";
import AddRoutes from "@/views/Route/AddRoutes.vue";
import GeofencesAdd from "./AddGeofences.vue";
export default {
  components: {
    MenuIcoIn,
    MenuIcoOut,
    VBTooltip,
    GeofencesList,
    GeofencesAdd,
    RoutesList,
    AddRoutes,
    BTabs,
    BTab
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  data() {
    return {
      touchStartY: 0,
      open: true,
      positionClass: false,
      selectedTab: "geofence"
    };
  },
  mounted() {
    if (this.$route.name.includes("geofence")) {
      this.selectedTab = "geofence";
    } else {
      if (this.$route.name.includes("route")) {
        this.selectedTab = "route";
      }
    }
    this.setTab();
  },

  props: ["openMapClass", "closeMenu", "geofenceControl"],
  computed: {},
  watch: {
    "$route.name"(to, from) {
      if (to === "geofences-list") {
        this.activeTab("geofence");
      } else if (to === "routes-list") {
        this.activeTab("route");
      }
    }
  },
  methods: {
    onTouchStart(event) {
      this.touchStartY = event.touches[0].clientY;
    },
    setTab() {
      this.$emit("getCurrentTab", this.selectedTab);
    },
    activeTab(tab) {
      this.selectedTab = tab;
      if (this.selectedTab === "geofence") {
        this.$router.push({ name: "geofences-list" }).catch((e) => {});
      } else if (this.selectedTab === "route") {
        this.$router.push({ name: "routes-list" }).catch((e) => {});
      }
      this.setTab();
    },
    setSearchKey(key) {
      this.$emit("setSearchKey", key);
    },
    onTouchMove(event) {
      const touchCurrentY = event.touches[0].clientY;
      const deltaY = this.touchStartY - touchCurrentY;
      if (deltaY > 100) {
        this.handleSwipeUp();
      } else {
        this.handleSwipeDown();
      }
    },
    onTouchEnd() {
      this.touchStartY = 0;
    },
    handleSwipeUp() {
      store.commit("mapLoader/SET_IS_POPUP_SHOW", true);
    },
    handleSwipeDown() {
      store.commit("mapLoader/SET_IS_POPUP_SHOW", false);
    },
    onTouchclick() {
      // store.commit("mapLoader/SET_IS_POPUP_SHOW", !this.positionClass);
      this.positionClass = !this.positionClass;
    },
    toggleNav: function () {
      this.open = !this.open;
      this.$emit("toggle", this.open);
      this.openMapClass(this.open);
    }
  }
};
</script>

<style lang="scss">
.menu__nav {
  position: absolute;
  top: 0;
  right: -400px;
  height: 97%;
  width: 400px;
  // overflow-y: scroll;
  transition: all 0.3s;
  z-index: 11;
  min-height: calc(100vh - 107px);
  border-radius: 0px 0px 0px 8px;
  background: var(--white);
  box-shadow: 0px 0px 15px 0px rgba(34, 41, 47, 0.05);
  padding: 18px;
}
.menu__nav--open {
  // overflow: hidden; remove hidden because cut bounce button on map screen and calender hidden.
  right: 0;
}

.toggle-button {
  background-color: var(--primary);
  width: 50px;
  padding: 8px 10px;
  border-radius: 6px 0px 0px 6px;
  position: absolute;
  z-index: 991;
  right: 0px;
  top: 8px;
  transition: all 0.3s;
  cursor: pointer;
  &.toggle-open {
    right: 400px;
  }
}
.search-outer {
  display: flex;
  width: 100%;
  margin-bottom: 12px;
  .search-filter {
    width: 100%;
    .pr-1 {
      display: none;
    }
    .form-group {
      width: 100%;
    }
  }
}

@media screen and (max-width: 667px) {
  .toggle-button {
    display: none;
  }
  .menu__nav {
    right: 0;
    left: 0;
    top: 82%;
    width: 100%;
    max-width: 100%;
    position: fixed;
    padding: 8px 10px;
    margin: 0 auto;
    // z-index: 9998; updated because menu overlap on active session popup
    z-index: 9999;
    border-radius: 8px 8px 8px 8px;
    transition: all 0.5s;
  }
  .bounce {
    width: 35px;
    height: 35px;
    background: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: calc(50% - 15px);
    margin-top: -35px;
    border-radius: 50%;
    position: relative;
    .iconpos {
      -moz-animation: bounce 2s infinite;
      -webkit-animation: bounce 2s infinite;
      animation: bounce 2s infinite;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: inherit;
      border-radius: 50%;
      z-index: -1;
      animation: ripple 1.5s ease-out 2 forwards;
    }
  }
  @keyframes ripple {
    from {
      opacity: 1;
      transform: scale(0);
    }
    to {
      opacity: 0;
      transform: scale(2);
    }
  }
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
    60% {
      transform: translateY(-5px);
    }
  }
  .menu__nav--open {
    top: 10%;
  }
  .menu__nav.up {
    top: 15%;
  }
  .menu__nav.down {
    top: calc(100% - 104px);
  }
}
.dark-layout {
  .menu__nav {
    background-color: var(--dark46);
  }
  .unit-search-v2 {
    &.v-2-unit {
      // margin-bottom: 12px;
    }
    .vs__dropdown-menu {
      background: var(--unit-bg-dark);
      border-radius: 4px;
    }
  }
}

@media screen and (min-width: 767px) and (max-width: 1200px) {
  .menu__nav,
  .toggle-button {
    z-index: 98;
  }
}
.geofece-tab {
  .tab-content .card-body {
    min-height: auto !important;
  }
}
</style>
