<template>
  <div class="optimize-card">
    <div v-if="!optimizeLoad">
      <div class="d-flex align-items-center justify-content-between">
        <div class="delete-header">
          <feather-icon
            :title="$t('route.DeleteAll')"
            v-b-tooltip.hover.v-primary
            :class="!isEditable ? 'disabled' : 'pointer'"
            icon="Trash2Icon"
            class="text-danger"
            size="18"
            @click="remove()"
          ></feather-icon>
          <span>{{ $t("Geofence.Delete") }}</span>
        </div>
        <div>
          <b-button
            :disabled="!isEditable"
            variant="primary"
            @click="
              (e) => {
                $emit('optimizeRoute', {});
              }
            "
            size="sm"
            class="optimize-btn"
            >{{ $t("Geofence.Optimize") }}</b-button
          >
        </div>
      </div>
      <div class="sort-list">
        <draggable @end="dragEnd" v-model="draggableItems">
          <div
            class="item-cell"
            v-for="(item, index) in draggableItems"
            :key="index"
          >
            <div class="list-item">
              <div class="check-outer-geo">
                <div class="drag" :class="!isEditable ? 'disabled' : ''">
                  <DragDrop />
                </div>

                <div class="d-flex align-items-center">
                  <div class="poly-outer" v-if="item.type === 'geofence'">
                    <PolygonImg
                      :style="{ fill: item.data && item.data.color }"
                    />
                  </div>
                  <div
                    class="mr-75 marginLeftImg unit-url"
                    v-if="['unit', 'point'].includes(item.type)"
                  >
                    <b-avatar size="35" :src="item.data && item.data.icon" />
                  </div>
                  <span
                    :class="
                      item.data && item.data.name && item.data.name.length > 6
                        ? 'checkpoint-name-1'
                        : 'checkpoint-name-1'
                    "
                    :title="
                      item.data && item.data.name && item.data.name.length > 6
                        ? item.data.name
                        : ''
                    "
                    >{{ item.data && item.data.name }}</span
                  >
                </div>
              </div>
              <b-form-input
                :disabled="!isEditable"
                min="1"
                max="100000"
                v-model="item.radius"
                type="number"
                style="
                  max-width: 90px;
                  padding: 0.438rem 0.5rem !important;
                  text-align: right;
                "
              />M

              <feather-icon
                :title="$t('route.Delete')"
                v-b-tooltip.hover.v-primary
                :class="!isEditable ? 'disabled' : 'pointer'"
                icon="Trash2Icon"
                class="text-danger mrl"
                size="18"
                @click="remove(item)"
              ></feather-icon>
            </div>
            <!-- <div class="location-rd">
            <RedLocation class="location-icon" />
            <span>{{ item.data && item.data.distance }}</span>
          </div> -->
          </div>
        </draggable>
      </div>
    </div>
    <div v-else>
      <b-skeleton-table
        :rows="10"
        :columns="4"
        :table-props="{ bordered: false, striped: false }"
      ></b-skeleton-table>
    </div>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BButton,
  BAvatar,
  BFormInput,
  VBTooltip,
  BSkeletonTable
} from "bootstrap-vue";
import draggable from "vuedraggable";
import DragDrop from "@/assets/images/drag.svg";
import RedLocation from "@/assets/images/red-location.svg";
import PolygonImg from "@/assets/images/polygon.svg";
import route from "@/store/route";
export default {
  components: {
    draggable,
    DragDrop,
    RedLocation,
    PolygonImg,
    BFormCheckbox,
    BButton,
    BAvatar,
    BFormInput,
    BSkeletonTable
  },
  props: ["value", "geofenceControl", "route", "isEditable", "optimizeLoad"],
  data() {
    return {
      draggableItems: [],
      content: 100
    };
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  watch: {
    value() {
      this.draggableItems = this.value;
    }
  },
  mounted() {
    this.draggableItems = this.value;
  },
  methods: {
    remove(item) {
      this.$emit("removedFromList", item || null);
    },

    dragEnd() {
      this.$emit("value", this.draggableItems);
      this.geofenceControl.createSinglePolyline(this.route);
    }
  }
};
</script>
<style lang="scss" scoped>
.item-cell {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.drag {
  margin-right: 10px;
}
.location-rd {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5px;
  padding-top: 5px;
  min-width: 45px;
  span {
    font-size: 12px;
    margin-top: 6px;
    position: relative;
    z-index: 1;
    background: #fff;
  }
  &:after {
    content: "";
    height: 140%;
    border-left: 2px dotted #000;
    position: absolute;
    z-index: 0;
  }
  .location-icon {
    position: relative;
    z-index: 1;
  }
}
.item-cell:last-child {
  .location-rd {
    &:after {
      border: none;
    }
  }
}
.optimize-card {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
  padding: 10px;
}
.dark-layout {
  .location-rd {
    span {
      background: var(--dark-bg);
    }
    &:after {
      border-left: 2px dotted #fff;
    }
  }
  .optimize-card {
    background: var(--dark-bg);
  }
}
.sort-list {
  margin-top: 10px;
}
.list-item {
  background: rgba(115, 103, 240, 0.18);
  padding: 7px 5px 7px 12px;
  position: relative;
  width: calc(100% - 1px);
  margin-bottom: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .mrl {
    margin-right: 10px;
  }
  .poly-outer {
    height: 35px;
    width: 35px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.18);
    -moz-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.18);
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.18);
    margin-left: 4px;
    margin-right: 7px;
  }
}
.delete-header {
  display: flex;
  align-items: center;
  // cursor: pointer;
  span {
    margin-left: 5px;
  }
}
.optimize-btn {
  min-height: auto;
}
</style>
<style lang="scss">
.check-outer-geo {
  display: flex;
  align-items: center;
  .custom-checkbox {
    .custom-control-label {
      cursor: pointer;
    }
    .custom-control-label::before,
    .custom-control-label::after {
      top: 23%;
      bottom: 23%;
    }
  }
}
.checkpoint-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 62px;
  min-width: 61px;
  max-width: 62px;
  display: block;
}
.checkpoint-name-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 62px;
  min-width: 61px;
  max-width: 62px;
  display: block;
}
.pointer {
  cursor: pointer;
}
</style>
