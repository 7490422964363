import L, { latLng } from "leaflet";
import "leaflet-draw"; // Make sure to include Leaflet Draw in your project
import "@/libs/map/css/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import "@/libs/map/js/leaflet-routing-machine.js";
import "@/libs/map/css/leaflet-routing-machine.css";
// Extend the Leaflet Control to create a custom Geofence control
L.Control.Geofence = L.Control.extend({
  options: {
    data: []
  },

  initialize: function (options) {
    L.Control.prototype.initialize.call(this, options);
    this._data = [];
    this.currentDraw = {};
    this.events = new L.Evented();
    this.optimizedRoute;
  },

  onAdd: function (map) {
    this._map = map;
    this._initLayout();

    this.drawnItems = new L.FeatureGroup();
    map.addLayer(this.drawnItems);

    const drawControl = new L.Control.Draw({
      draw: {
        marker: true,
        polyline: false,
        polygon: true,
        rectangle: true,
        circle: true
      },
      edit: {
        featureGroup: this.drawnItems
      }
    });
    map.addControl(drawControl);

    map.on("draw:created", this._onDrawCreated.bind(this));
    map.on("draw:edited", this._onDrawEdited.bind(this));
    map.on("draw:deleted", this._onDrawDeleted.bind(this));
    map.on("draw:editstart", this._onDrawStarted.bind(this));
    map.on("zoomend", this._onZoomEnd.bind(this));

    return this._container;
  },

  onRemove: function (map) {
    if (this._drawControl) {
      map.removeControl(this._drawControl);
    }
  },

  _initLayout: function () {
    this._container = L.DomUtil.create("div", "leaflet-bar leaflet-control");
  },

  _onDrawCreated: function (event) {
    this._event = event;
    const map = this._map;

    // Remove existing layers
    if (event && event.layerType !== "marker") {
      map.eachLayer((layer) => {
        if (
          layer instanceof L.Polyline ||
          layer instanceof L.Marker ||
          layer instanceof L.Circle
        ) {
          layer.remove();
        }
      });

      this.removeAllLayers();
    }

    const data = { fenceType: event.layerType };
    const layer = event.layer;

    // Assign a unique custom ID to each layer
    const customId = new Date().getTime().toString();
    layer.options.customId = customId;
    layer.options["fill-opacity"] = "0.5";
    layer.options.weight = "2";
    let id;

    if (layer instanceof L.Polyline) {
      this.drawnItems.addLayer(layer);
      data.latlngs = layer._latlngs;
    } else if (layer instanceof L.Circle) {
      data.latlngs = latLng(layer._latlng);
      data.center = latLng(layer._latlng);
      data.radius = layer._mRadius;
      this.drawnItems.addLayer(layer);
    } else if (layer instanceof L.Marker) {
      data.latlngs = [latLng(layer._latlng)];
      const markerId = Math.floor(Math.random() * 10000) + 1;
      layer.options.weight = "2";
      id = markerId;
      data.id = markerId;
      this.drawnItems.addLayer(layer);
    }
    if (id) {
      layer.options.id = id;
    }
    this.events.fire("drawGeoFence", { ...data, layer });
    this._data.push(layer);
    this.currentDraw = data;
    this._updateShapeInfo();
    this._updateLayerColor();

    this.fitBounds();
  },

  _onDrawEdited: function (event) {
    const layers = event.layers;
    const data = {};
    layers.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        this.currentDraw.latlngs = [layer._latlng];
        data.latlngs = [layer._latlng];
        data.layer = layer;
        data.type = "marker";
      } else if (
        layer instanceof L.Rectangle ||
        layer instanceof L.Polygon ||
        layer instanceof L.Polyline
      ) {
        this.currentDraw.latlngs = layer._latlngs;
        data.latlngs = layer._latlngs;
        data.type = "polygon";
        data.layer = layer;
      } else if (layer instanceof L.Circle) {
        this.currentDraw.center = latLng(layer._latlng);
        this.currentDraw.latlngs = latLng(layer._latlng);
        this.currentDraw.radius = layer._mRadius;
        data.center = latLng(layer._latlng);
        data.latlngs = latLng(layer._latlng);
        data.radius = layer._mRadius;
        data.layer = layer;
        data.type = "circle";
      }
      this.events.fire("drawGeoFence", { ...data, fenceTypes: data.type });
      this._updateShapeInfo();
    });
  },

  addLayer: function ({
    type,
    coordinates,
    id,
    visibility_start,
    visibility_end,
    radius,
    color,
    rotationAngle,
    icon,
    route_id
  }) {
    let layer;
    const geoOptions = {
      id,
      visibility_start,
      visibility_end,
      color,
      type,
      radius,
      "fill-opacity": "0.5",
      weight: "2",
      rotationAngle,
      icon,
      route_id
    };

    switch (type) {
      case "circle":
        layer = L.circle(coordinates, { ...geoOptions });
        break;
      case "rectangle":
        layer = L.rectangle(coordinates, { ...geoOptions });
        break;
      case "polygon":
        layer = L.polygon(coordinates, { ...geoOptions });
        break;
      case "marker":
        if (geoOptions.icon) {
          geoOptions.icon = L.icon({ iconUrl: geoOptions.icon });
        }
        layer = L.marker(coordinates, { ...geoOptions });
        break;
      default:
        console.error("Invalid layer type");
        return;
    }

    this.drawnItems.addLayer(layer);
    this._data.push({ layer });
    this.fitBounds();
    this.currentDraw = layer;

    return layer;
  },

  _onDrawDeleted: function (event) {
    const layers = event.layers;
    layers.eachLayer((layer) => {
      const id = layer.options.customId;
      this._data = this._data.filter((l) => l.layer.options.id !== id);
      this.drawnItems.removeLayer(layer);
    });
    this.events.fire("removedGeoFence", {});
    this._updateShapeInfo();
  },

  _onDrawStarted: function () {
    this._buttonDisable = true;
  },

  fitBounds: function () {
    setTimeout(() => {
      let bounds = L.latLngBounds();
      this._map.eachLayer((layer) => {
        if (
          layer instanceof L.Marker ||
          layer instanceof L.Polygon ||
          layer instanceof L.Polyline
        ) {
          bounds.extend(
            layer.getBounds ? layer.getBounds() : layer.getLatLng()
          );
        } else if (layer instanceof L.Circle) {
          const center = layer.getLatLng();
          const radius = layer.getRadius();
          const northEast = L.latLng(
            center.lat + (radius / 40008000) * 360,
            center.lng +
              ((radius / 40008000) * 360) /
                Math.cos((center.lat * Math.PI) / 180)
          );
          const southWest = L.latLng(
            center.lat - (radius / 40008000) * 360,
            center.lng -
              ((radius / 40008000) * 360) /
                Math.cos((center.lat * Math.PI) / 180)
          );
          bounds.extend(L.latLngBounds(northEast, southWest));
        }
      });
      if (bounds.isValid()) {
        this._map.fitBounds(bounds);
      }
    }, 400);
  },

  _onZoomEnd: function () {
    const zoomLevel = this._map.getZoom();
    this.drawnItems.eachLayer((layer) => {
      const layerData = this._data.find(
        (l) =>
          l.layer && l.layer.options && l.layer.options.id === layer.options.id
      );
      if (layerData && layerData.layer) {
        const visibilityStart = layerData.layer.options.visibility_start || 0;
        const visibilityEnd = layerData.layer.options.visibility_end || 19;
        if (zoomLevel >= visibilityStart && zoomLevel <= visibilityEnd) {
          layer.addTo(this._map);
        } else {
          this._map.removeLayer(layer);
        }
      }
    });
  },

  removeLayerById: function (id) {
    const layerData = this._data.find(
      (l) => l.layer && l.layer.options && l.layer.options.id === id
    );
    this._map.eachLayer((layer) => {
      if (layer instanceof L.Marker && layer.options.id === id) {
        this._map.removeLayer(layer);
      }
    });
    if (layerData) {
      this.drawnItems.eachLayer((layer) => {
        if (layer.options && layer.options.id === id) {
          this.drawnItems.removeLayer(layer);
        }
      });
      try {
        this._data = this._data.filter((l) => l.layer.options.id !== id);
      } catch (e) {}
    }

    this.fitBounds();
  },
  removeLayerByRouteId: function (id) {
    const layerData = this._data.find(
      (l) => l.layer && l.layer.options && l.layer.options.route_id === id
    );

    if (layerData) {
      this.drawnItems.eachLayer((layer) => {
        if (layer.options && layer.options.route_id === id) {
          this.drawnItems.removeLayer(layer);
        }
      });
      this._data = this._data.filter((l) => l.layer.options.route_id !== id);
    }
    this._map.eachLayer(function (layer) {
      if (
        (layer instanceof L.Polyline || layer instanceof L.Marker) &&
        layer.options.route_id === id
      ) {
        this._map.removeLayer(layer);
      }
    }, this);

    this.fitBounds();
  },
  // Function to calculate distance between two points
  calculateDistance: function (latlng1, latlng2) {
    return this._map.distance(latlng1, latlng2).toFixed(2); // in meters
  },

  // Function to get all corners (or vertices) of a shape
  getShapeCorners: function (layer) {
    if (layer instanceof L.Polygon || layer instanceof L.Rectangle) {
      return layer.getLatLngs()[0]; // For polygons and rectangles
    } else if (layer instanceof L.Circle || layer instanceof L.Marker) {
      return [layer.getLatLng()]; // For circles and markers, use center
    }
    return [];
  },

  optimizeRoute: function (route) {
    if (!route.id) {
      return;
    }
    const layers = this.drawnItems.getLayers();

    if (this.optimizedRoute) {
      this._map.removeControl(this.optimizedRoute);
    }

    // Create an array of waypoints with their corresponding layers and IDs
    var waypoints = layers.map((shape) => {
      const position = shape.getBounds
        ? shape.getBounds().getCenter()
        : shape.getLatLng();
      return {
        latLng: position,
        id: shape.options.id // Store the layer's ID
      };
    });

    // Shuffle the waypoints to simulate a different optimized route
    waypoints.sort(() => Math.random() - 0.5);

    // Create a Routing control with the custom waypoints
    this.optimizedRoute = L.Routing.control({
      waypoints: waypoints.map((wp) =>
        L.latLng(wp.latLng.lat, wp.latLng.lng, { id: wp.id })
      ),
      routeWhileDragging: false,
      createMarker: (i, wp) => {
        // Create a custom icon with the route order number
        const icon = L.divIcon({
          className: "custom-div-icon",
          html: `<div style="background-color: green; color: white; border-radius: 50%; width: 30px; height: 30px; display: flex; align-items: center; justify-content: center;">${
            i + 1
          }</div>`,
          iconSize: [30, 30]
        });

        // Return the marker with the custom icon
        return L.marker(wp.latLng, { icon: icon });
      },
      lineOptions: {
        styles: [{ color: "green", weight: 4 }]
      }
    })
      .on("routesfound", (e) => {
        // Event triggered after the route is found and optimized
        const route = e.routes[0];

        // Retrieve the custom IDs from the waypoints in the route
        const orderedWaypoints = route.waypoints.map((wp, i) => {
          // Access the custom ID attached earlier
          const id = wp.options.id || waypoints[i].id; // Fallback to original ID

          return {
            index: i + 1, // Route order starting from 1
            layerId: id // The corresponding layer ID
          };
        });

        // Fire your custom event with the ordered waypoints
        this.events.fire("routeOptimized", {
          orderedWaypoints: orderedWaypoints
        });

        // You can handle additional logic here if needed
      })
      .addTo(this._map);
  },
  // Function to create a single polyline connecting nearest shape corners
  createSinglePolyline: function (route) {
    if (!route.id) {
      return;
    }
    if (this.optimizedRoute) {
      this._map.removeControl(this.optimizedRoute);
    }
    const layers =
      this.drawnItems
        .getLayers()
        .filter((layer) => layer.options.route_id === route.id) || [];

    if (layers.length === 0) return;

    const polylinePoints = [];
    const distances = [];
    const connectedLayers = [];

    // Generate waypoints for each layer
    const waypoints = layers.map((shape) => {
      const position = shape.getBounds
        ? shape.getBounds().getCenter()
        : shape.getLatLng();
      return {
        latLng: position,
        id: shape.options.id, // Store the layer's ID
        layer: shape
      };
    });

    // Start with the first shape
    const firstLayer = waypoints[0].layer;
    connectedLayers.push(firstLayer);
    polylinePoints.push(...this.getShapeCorners(firstLayer));

    // Add marker for the 0th index
    let center;
    try {
      center = firstLayer.getBounds().getCenter();
    } catch {
      center = firstLayer._latlng;
    }
    L.marker(center, {
      id: firstLayer.options.id,
      route_id: route.id,
      isInfo: true,
      icon: L.divIcon({
        className: "index-marker",
        html: `<div style="background-color: green;color: white; border: 1px solid white; border-radius: 50%; width: 20px; height: 20px; text-align: center;">1</div>`
      })
    }).addTo(this._map);

    // Initialize index counter for this route
    let index = 2; // Start from 2 because the first marker is already added

    while (connectedLayers.length < layers.length) {
      const lastLayer = connectedLayers[connectedLayers.length - 1];

      // Find the nearest waypoint from the remaining waypoints
      let nearestWaypoint = null;
      let shortestDistance = Infinity;

      waypoints.forEach((waypoint) => {
        if (!connectedLayers.includes(waypoint.layer)) {
          const lastLayerCorners = this.getShapeCorners(lastLayer);
          const nearestCorner = this.findNearestCorner(
            lastLayerCorners,
            waypoint.layer
          );

          const distance = this.calculateDistance(
            nearestCorner.refCorner,
            nearestCorner.targetCorner
          );

          if (distance < shortestDistance) {
            shortestDistance = distance;
            nearestWaypoint = waypoint;
          }
        }
      });

      if (nearestWaypoint) {
        connectedLayers.push(nearestWaypoint.layer);
        polylinePoints.push(nearestWaypoint.latLng);
        distances.push(shortestDistance);

        let center;
        try {
          center = nearestWaypoint.layer.getBounds().getCenter();
        } catch {
          center = nearestWaypoint.layer._latlng;
        }

        // Add a marker with the index number to each connected shape
        L.marker(center, {
          id: nearestWaypoint.layer.options.id,
          route_id: route.id,
          isInfo: true,
          icon: L.divIcon({
            className: "index-marker",
            html: `<div style="background-color: green;color: white; border: 1px solid white; border-radius: 50%; width: 20px; height: 20px; text-align: center;">${index}</div>`
          })
        }).addTo(this._map);

        index++; // Increment index for each connected layer
      }
    }

    // Close the loop
    polylinePoints.push(this.getShapeCorners(layers[0])[0]); // Closing the loop with the first shape

    // Draw the polyline
    L.polyline(polylinePoints, {
      color: route.color || "blue",
      weight: 2,
      route_id: route.id,
      dashArray: "5, 10" // Dotted line style
    }).addTo(this._map);
  },

  // Function to get all corners (or vertices) of a shape
  getShapeCorners: function (layer) {
    if (layer instanceof L.Polygon || layer instanceof L.Rectangle) {
      return layer.getLatLngs()[0]; // For polygons and rectangles
    } else if (layer instanceof L.Circle || layer instanceof L.Marker) {
      return [layer.getLatLng()]; // For circles and markers, use center
    }
    return [];
  },

  // Function to find the nearest corner from one shape to another
  findNearestCorner: function (referenceCorners, targetLayer) {
    let nearestCorner = null;
    let shortestDistance = Infinity;
    const targetCorners = this.getShapeCorners(targetLayer);

    for (const refCorner of referenceCorners) {
      for (const targetCorner of targetCorners) {
        const distance = this._map.distance(refCorner, targetCorner);
        if (distance < shortestDistance) {
          shortestDistance = distance;
          nearestCorner = { refCorner, targetCorner };
        }
      }
    }

    return nearestCorner;
  },

  // Function to connect each shape's nearest corner to the nearest corner of another shape

  removeAllLayers: function () {
    this.drawnItems.clearLayers();
    this._data = [];
    this._map.eachLayer(function (layer) {
      if (layer instanceof L.Polyline || layer instanceof L.Marker) {
        this._map.removeLayer(layer);
      }
    }, this);
    if (this.optimizedRoute) {
      this._map.removeControl(this.optimizedRoute);
    }
    this.fitBounds();
  },
  removeMarker: function () {
    this._map.eachLayer(function (layer) {
      if (
        (layer instanceof L.Polyline || layer instanceof L.Marker) &&
        layer.options.isInfo === true
      ) {
        this._map.removeLayer(layer);
      }
    }, this);
  },
  removePolyline: function () {
    this._map.eachLayer(function (layer) {
      if (layer instanceof L.Polyline) {
        this._map.removeLayer(layer);
      }
    }, this);
    if (this.optimizedRoute) {
      this._map.removeControl(this.optimizedRoute);
    }
    this.fitBounds();
  },

  _updateShapeInfo: function () {
    // console.log("Shape info updated");
  },

  _updateLayerColor: function () {
    // console.log("Layer color updated");
  }
});

// Minified by jsDelivr using Terser v5.19.2. Original file: /npm/leaflet-rotatedmarker@0.2.0/leaflet.rotatedMarker.js
!(function () {
  var t = L.Marker.prototype._initIcon,
    o = L.Marker.prototype._setPos,
    i = "msTransform" === L.DomUtil.TRANSFORM;
  L.Marker.addInitHook(function () {
    var t =
      this.options.icon &&
      this.options.icon.options &&
      this.options.icon.options.iconAnchor;
    t && (t = t[0] + "px " + t[1] + "px"),
      (this.options.rotationOrigin =
        this.options.rotationOrigin || t || "center bottom"),
      (this.options.rotationAngle = this.options.rotationAngle || 0),
      this.on("drag", function (t) {
        t.target._applyRotation();
      });
  }),
    L.Marker.include({
      _initIcon: function () {
        t.call(this);
      },
      _setPos: function (t) {
        o.call(this, t), this._applyRotation();
      },
      _applyRotation: function () {
        this.options.rotationAngle &&
          ((this._icon.style[L.DomUtil.TRANSFORM + "Origin"] =
            this.options.rotationOrigin),
          i
            ? (this._icon.style[L.DomUtil.TRANSFORM] =
                "rotate(" + this.options.rotationAngle + "deg)")
            : (this._icon.style[L.DomUtil.TRANSFORM] +=
                " rotateZ(" + this.options.rotationAngle + "deg)"));
      },
      setRotationAngle: function (t) {
        return (this.options.rotationAngle = t), this.update(), this;
      },
      setRotationOrigin: function (t) {
        return (this.options.rotationOrigin = t), this.update(), this;
      }
    });
})();

L.control.geofence = function (options) {
  return new L.Control.Geofence(options);
};
