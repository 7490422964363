import MapLayerList from "./MapLayerList";

export default class MapLayers {
  constructor(map) {
    if (map.layersAttached) {
      return;
    }
    map.layersAttached = true;
    const mapLayer =
      localStorage.getItem("default_map_layer") || "OpenStreetMap";
    if (!map) {
      throw "Map object is not valid to initiate the MapLayers class";
    }
    this.map = map;
    const baseMaps = MapLayerList;
    if (mapLayer && baseMaps[mapLayer]) {
      baseMaps[mapLayer].addTo(map);
    }
    L.control.layers(baseMaps).addTo(map);
    map.on("baselayerchange", (layer) => {
      if (layer.name != mapLayer) {
        localStorage.setItem("default_map_layer", layer.name);
      }
    });
  }
}
