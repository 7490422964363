<template>
  <b-row>
    <b-col md="12" class="d-flex">
      <b-form-input
        class="color-border-0-right"
        v-model="inputVal"
        type="text"
        :disabled="true"
      >
      </b-form-input>
      <b-form-input
        class="color-border-0-left"
        style="width: 50px"
        v-model="inputVal"
        @input="updateInputState"
        type="color"
        :disabled="!isEditable"
      >
      </b-form-input>
    </b-col>
  </b-row>
</template>

<script>
import { BFormInput, BRow, BCol } from "bootstrap-vue";

export default {
  props: [
    "name",
    "desc",
    "label",
    "data_type",
    "component",
    "value",
    "isEditable",
    "isRequired"
  ],
  components: { BFormInput, BRow, BCol },
  data() {
    return {
      inputVal: "",
      isIndeterminate: false
    };
  },
  mounted() {
    this.inputVal = this.value;
  },
  watch: {
    value(old, newVal) {
      if (this.value && this.inputVal !== this.value) {
        this.inputVal = this.value;
      }
    }
  },
  methods: {
    updateInputState() {
      this.$emit("input", this.inputVal);
    }
  }
};
</script>
<style scoped>
.color-border-0-right {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.color-border-0-left {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
</style>
